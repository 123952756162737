.inputNew {
  position: relative;
  width: 100%;
  min-height: 30px;

  input {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 54px;
    margin-bottom: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 14px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    background-color: #fcfcfc;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    -webkit-transition: border-color 200ms ease, box-shadow 200ms ease, background-color 200ms ease;
    transition: border-color 200ms ease, box-shadow 200ms ease, background-color 200ms ease;
    color: #333;
    font-size: 16px;
    text-align: left;
    width: 100%;
    line-height: 1.42857143;
    vertical-align: middle;

    &:focus {
      border-style: solid;
      border-width: 1px;
      font-size: 16px;

      border-color: #c9c9c9;
      background-color: #fff;
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0), 0 0 4px 0 #8f8f8f;
      outline: none;
    }
  }
}

.buttonNew {
  height: 49px;
  margin-top: 10px;

  button {
    color: white;
    border: 0;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    padding: 13px 18px 12px;
    border-radius: 4px;
    background-color: red;
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
    font-size: 15px;
    line-height: 24px;
    font-weight: 700;
    max-height: 49px;

    .rmwc-circular-progress {
      color: #fff;
    }
  }
}

.forgotPassword {
  font-size: 15px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  text-decoration: none;
  color: var(--mainColor);
  margin-top: 5px;
}

.platform {
  > p {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;
  }

  .robomarts {
    display: flex;
    justify-content: space-around;
    padding: 0 250px;
    margin-bottom: 20px;

    .robo {
      display: flex;
      box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
      border-radius: 5px;
      width: 300px;
      justify-content: space-between;
      padding: 15px;
      background-color: #fbfbfb;

      .img {
        width: 35%;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        width: 65%;

        .title {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-bottom: 10px;

          .type {
            font-size: 15px;
          }
        }

        .actions {
          display: flex;
          justify-content: space-between;

          input {
            margin-bottom: 10px;
            width: 75px;
            height: 30px;
            padding-left: 5px;
            background: transparent;
            border: 1px solid #ccc;
            border-radius: 6px;

            &:focus {
              outline: none;

            }
          }

          button {
            height: 30px;
            outline: none;
            background-color: #2d7ff9;
            border-radius: 6px;
            color: #fff;
            width: 75px;
            border: none;
            cursor: pointer;
          }
        }
      }
    }
  }

  .secondRow {
    padding: 0 400px;
  }

  .total {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 18px;
  }
}

#gallery {
  .slider {
    outline: none;
  }

  .slider-control-bottomcenter {
    bottom: -55px !important;
  }

  .slide-visible {
    outline: none;
  }

  .fas {
    cursor: pointer;
    color: #ccc;

    &.disabled {
      cursor: default;
      opacity: 0.5;
    }
  }

  .galleryBottomControls {
    display: flex;
    max-width: 100%;

    img {
      width: 50px;
      height: 50px;
      border: 1px solid #ccc;
      margin-right: 3px;
      border-radius: 5px;
      cursor: pointer;
      object-fit: contain;

      &:hover, &.active {
        border: 2px solid var(--primary-color);
      }
    }

    .dot {
      background: #ccc;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin: 10px 5px;


      &.active {
        background: var(--primary-color);
      }
    }

  }

  .imageGallerySlider {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    flex-direction: column;
    width: 350px;
    height: 300px;
    position: relative;


    &.deleting {
      filter: blur(5px);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      outline: none !important;
    }

  }


}
