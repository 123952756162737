@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

$red: #f83f37;
$bg: #f3f6f9;
$primary: #2f78bb;
$active: green;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &, body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }
}

:root {
  --mainColor: #333333;
}

body {
  font-family: Poppins, sans-serif;
  color: var(--mainColor);
}

ul {
  list-style-type: none;

}

.loginContainer {
  width: 100%;
  min-width: 550px;
  @media (max-width: 1024px) {
    min-width: 390px;
  }

  .loginForm {
    max-width: 450px;
    margin: 15% auto 0;
    padding: 25px;
    background: #fff;
    //box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    .title, .desc {
      display: flex;
      justify-content: center;
    }

    .title {
      font-size: 18px;
    }

    .desc {
      font-size: 12px;
      text-align: center;
      color: #333;
      opacity: 0.5;
      margin-top: 5px;
    }

    .logo {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;

      img {
        width: 250px;
        height: 50px;
      }
    }

    .title {
      font-size: 16px;
    }

    .formFields {
      padding-top: 10px;

      a {
        text-decoration: none;
      }

    }


  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.rmInput, .rmTextarea {
  width: 100%;
  position: relative;

  .invalid {
    position: absolute;
    left: 0;
    font-size: 12px;
    bottom: 0;
    color: $red;
  }


  &.blur {
    pointer-events: none;

    .mdc-text-field {
      filter: blur(2px);
    }
  }

  .mdc-text-field {
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
    background: transparent;
    font-size: 15px;

    &:hover {
      background: transparent;

    }
  }

  .mdc-floating-label {
    font-size: 14px;
    font-family: unset;

  }

  input[readonly], textarea[readonly] {
    background-color: #ececec;
  }

  .mdc-text-field__input {
    font-size: 15px;
    font-family: "Poppins", sans-serif;
  }

}

.rmTextarea {
  textarea {
    resize: none;

  }

  .mdc-text-field {
    height: 200px;
    width: 100%;
  }
}

.rmButton {
  .mdc-button {
    width: 100%;
  }
}

.mdc-top-app-bar {
  box-shadow: rgba(115, 108, 203, 0.1) 0 1px 10px 1px;
  padding: 0 20px;

  .mdc-top-app-bar__row {
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;

      .logo {
        width: 155px;
        height: 45px;
        margin-right: 10px;
      }

      .tabs {
        display: flex;
        align-items: center;
        height: 40px;
      
        .menu {
          cursor: pointer;
          margin-right: 10px;
          position: relative;
          top: 2px;
      
          .material-icons {
            font-size: 27px;
            display: flex;
          }
        }
      
        .tab {
          margin: 0 20px 0 0;
          text-decoration: none;
          color: #333;
          font-weight: 600;
          position: relative;
          top: 2px;
      
          &.active {
            color: $red;
          }
        }
      }
    }

  }
}

.wrapper {
  width: 100%;

  .container {
    width: 100%;
    display: flex;
    background: $bg;
    min-height: calc(100vh - 64px);

    .menuTransition-enter-done,
    .menuTransition-exit-done,
    .left {
      width: 15%;
      border-right: 1px solid #716f7b;
      position: fixed;
      height: 100%;
      overflow-y: auto;
      top: 0;
      left: 0;
      padding: 64px 5px 5px 5px;
      transition: all 300ms linear;
      min-width: 170px;
    }

    .menuTransition-enter {
      left: -15%;
    }

    .menuTransition-enter-active {
      left: 0;
    }

    .menuTransition-exit {
      left: 0;
    }

    .menuTransition-exit-done,
    .menuTransition-exit-active {
      left: -64%;
    }


    .right {
      width: 85%;
      padding: 10px;
      margin-left: 15%;
      transition: all 300ms linear;
      overflow: hidden;

      @media (max-width: 1024px) {
        margin-left: 170px;
        background: $bg;
      }

      &.open {
        width: 100%;
        margin-left: 0;

      }

    }
  }


}

.menuList {
  position: relative;
  height: calc(100vh - 70px);

  .version {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.mdc-list {
  .mdc-ripple-upgraded .mdc-list-item {
    height: 30px;
    font-size: 14px;


  }

  .leftMenuLink {
    a {
      width: 100%;
      height: 100%;
      text-decoration: none;
      color: #333333;
      display: flex;
      align-items: center;
    }
  }
}

.dataTable {
  width: 100%;

  .tableSelect {
    .rm__control {
      cursor: pointer;
    }
  }

  .selectable {
    width: 130px;

  }

  &.consumers {
    .charge_amount {
      > p {
        white-space: normal;
        line-height: 1.3em;
      }
    }

    .mdc-data-table__table {
      :nth-child(7), :nth-child(8), :nth-child(9) {
        max-width: 110px;
      }

    }
  }

  .datepicker {
    z-index: 10 !important;
  }

  .mdc-data-table {
    width: 100%;
    //max-height: calc(100vh - 270px);

    .rmwc-data-table__cell {

      p {
        width: 100%;
        height: 100%;
        padding: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }

    .mdc-data-table__cell, .rmwc-data-table__cell {
      border-right: 1px solid rgba(0, 0, 0, .12);
      overflow: unset;

      a {
        color: $primary;
        text-decoration: none;
      }

      &.customField {
        color: $primary;
        cursor: pointer;
      }
    }
  }
}

.addBlockForm {
  padding: 20px 5px;
  width: 100%;

  .addBlock {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .col {
      width: 48%;
    }


  }

  .actions {
    display: flex;
    margin-top: 10px;

    .rmButton {
      width: 100px;
      margin-right: 10px;
    }
  }
}


.breadcrumb {
  margin: 20px 0;

  a {
    text-decoration: none;
    color: #333333;
  }

  .mainMenu {
    font-size: 25px;
    font-weight: bold;

    &.blur {
      filter: blur(5px);
    }
  }

  .subMenus {
    span {
      cursor: pointer;
    }
  }

  .slash {
    margin: 0 5px;
    cursor: pointer;
  }

  .action.blur {
    filter: blur(5px);
  }
}

.merchants, .zones {
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    min-height: 25px;

    .loading {
      display: flex;
      align-items: flex-end;
    }

    .rmButton {
      width: 150px;

      .mdc-button__label {
        font-size: 12px;
      }
    }
  }
}

.material-icons {
  font-size: 15px;

  &.primary {
    color: $primary;
    cursor: pointer;
    margin-right: 5px;
  }

  &.active {
    color: $active;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.loader {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rmSelect {
  width: 100%;
  position: relative;
  height: 60px;

  &.invalid {
    .rm__control {
      border-color: $red;
    }

    .label {
      color: $red;
    }
  }

  &.tableSelect {

    .rm__single-value {
      margin-left: 6px;
      margin-top: 2px;
    }
  }

  &.blur {
    filter: blur(2px);
  }

  .label {
    position: absolute;
    top: 9px;
    pointer-events: none;
    left: 2px;
    padding: 0 0.75rem;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    font-size: 14px;
    color: rgba(0, 0, 0, .6);

    &.focused {
      top: 6px;
      transform: translateY(-25%) scale(0.75);
      color: #2f78bb;
      //letter-spacing: 0.5px;
    }
  }

  .rm__menu {
    z-index: 10;
  }

  .rm__option {
    min-height: 42px;
  }

  .rm__input {
    input {
      position: absolute;
      top: 10px;
      padding-top: 4px !important;
    }
  }

  .rm__control--is-disabled {
    background-color: transparent;
  }

  .rm--is-disabled {
    + .label {
      opacity: 0.5;
    }
  }

  .rm__single-value {
    margin-left: 6px;
    margin-top: 9px;
  }

  .rm__control {
    min-height: 40px;
    border-top: unset;
    border-left: unset;
    border-right: unset;
    border-radius: 0;
    outline: none;
    background: transparent;
    border-color: #8d8f91;
    border-width: 1px;

    .rm__value-container {
      font-size: 14px;
    }

    .rm__placeholder {
      display: none;
    }

    &.rm__control--is-focused {
      outline: none;
      box-shadow: none;
      border-top: unset;
      border-left: unset;
      border-right: unset;
      background-color: rgba(0, 0, 0, 0.1);
      border-width: 2px;
      border-color: #2f78bb;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
  }

  .rm__indicator-separator {
    display: none;
  }
}

.googleMaps {
  height: 400px;

  &.blur {
    filter: blur(2px);
  }


  .link {
    color: #2f78bb;
    text-decoration: underline;
    cursor: pointer;


  }

}


.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#5F5F5F, .56);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 198px;
  padding-bottom: 50px;
  overflow-y: auto;

  .modalContent {
    outline: none;
    width: 420px;
    height: 180px;
    background-color: #fff;
    border-radius: 8px;
    padding: 30px;

    &.createdTags {
      width: 600px;
      height: unset;

      .tags {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        span {
          font-size: 16px;
          font-weight: bold;
        }

        hr {
          width: 100%;
          margin: 10px 0;
        }

        .added, .existing {
          display: flex;
          flex-direction: column;
          width: 100%;

          .tagsAdded {
            font-size: 14px;
          }

          .mdc-data-table {
            width: 100%;
          }

          .tag {

            a {
              color: $primary;
              text-decoration: none;
            }
          }
        }

      }
    }

    &.changePassword {
      height: 270px;

      .top {
        display: flex;
        justify-content: space-between;
      }
    }

    &.inventoryStockList {
      width: 600px;
      height: unset;

      .mdc-data-table {
        width: 100%;
        max-height: 400px;

        .stockImg {
          width: 60px;
          height: 60px;
        }
      }

      .title {
        font-size: 16px;
        font-weight: 600;
      }

      p {
        width: unset;
      }

      hr {
        margin: 10px 0;
      }

      .top {
        display: flex;
        justify-content: space-between;

        .title {
          display: flex;
          align-items: center;

          span {
            margin-left: 10px;
            font-size: 16px;
            font-weight: 600;
          }
        }

        .robomartImg {
          width: 50px;

        }
      }
    }

    &.genCode, &.editInviteCode {
      height: 440px;

      .top {
        justify-content: space-between;
        margin-bottom: 10px;
      }

    }

    &.editInviteCode {
      height: 380px;
    }

    &.editApplicants, &.createVoucherModal {
      height: 530px;

      .top {
        justify-content: space-between;
        margin-bottom: 10px;
      }

      .type {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        span {
          font-size: 15px;
        }

        .applicantType {
          margin-left: 10px;
          font-weight: 600;
        }
      }
    }

    &.createVoucherModal {
      height: auto;

      .rmSelect {
        height: max-content;
        padding: 15px 0;

        .rm__input input {
          position: unset;
        }
      }
    }

    &.assignModal {
      height: 250px;

      .rmSelect {
        margin: 10px 0;
      }
    }

    &.viewTagModal, {
      min-height: 200px;
      height: unset;
      width: 600px;
    }

    .top {
      display: flex;
      justify-content: flex-end;

      .icon {
        cursor: pointer;
      }
    }

    .viewTag {
      display: flex;
      width: 100%;
      padding: 0 40px;

      .rmwc-data-table__head {
        .mdc-data-table__cell {
          font-weight: 600;
        }
      }

      .tag {
        max-width: 260px;
        min-width: 200px;
        white-space: normal;
        text-overflow: unset;
        word-break: break-all;

      }
    }

    p {
      display: flex;
      width: 100%;
      justify-content: center;
    }

    .actions {
      margin-top: 30px;
      display: flex;
      justify-content: space-around;
      width: 100%;

      .mdc-button {
        width: 100px;
      }
    }

  }

}

.rc-tooltip {
  &.hide {
    display: none;
  }
}

.inventory, .allTagsList {
  padding-bottom: 30px;

  .top {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: unset;
    border-radius: 5px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    .getInventory {
      justify-content: space-between;

      .rmSelect {
        min-width: 250px;
        width: 250px;
        max-width: 250px;
        height: 30px;

        .rm__control {
          border: 1px solid rgba(0, 0, 0, 0.12);
          border-radius: 4px;
          min-height: 30px;
          max-height: 30px;
        }

        .rm__single-value {
          margin-top: unset;
        }

        .rm__indicators {
          height: 30px;
          min-height: 30px;
          max-height: 30px;
        }
      }

      .baseline {
        display: flex;
        align-items: center;
      }
    }

    .lastUpdate, .getInventory {
      box-shadow: 0 1px rgba(0, 0, 0, 0.12);
      height: 56px;
      padding: 0 16px;
      display: flex;
      align-items: center;

      p, {
        margin-right: 10px;
      }

      .mdc-form-field {
        margin-right: 20px;
      }

      .mdc-button {
        height: 30px;
      }

      .bold {
        font-weight: 600;
      }
    }
  }

  .mdc-data-table {
    width: 100%;
    //max-height: calc(100vh - 250px);
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    .total {
      .mdc-data-table__cell {
        font-weight: 600;
      }
    }

    .mdc-data-table__cell, .rmwc-data-table__cell {
      border-right: 1px solid rgba(0, 0, 0, .12);

      a {
        color: $primary;
        text-decoration: none;
      }

      &.blue {
        color: $primary;
      }

      &.red {
        color: $red;
      }

      &.clickable {
        cursor: pointer;
      }

      &.lightGreen {
        background-color: #d9ead3;
      }

      &.lightBlue {
        background-color: #c9daf8;
      }

      &.lightRed {
        background-color: #f4cccc;
      }
    }
  }
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  margin-top: 20px;
}

.page-link {
  position: relative;
  color: $primary;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;

  &:hover {
    z-index: 2;
    color: $primary;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }

  &:focus {
    z-index: 3;
    color: $primary;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
}

.break {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.page-item {
  cursor: pointer;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

.page-item.disabled {
  cursor: default;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}


.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.addTag, .allTagsList, .profile {
  border: 1px solid rgba(0, 0, 0, .12);
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  padding: 20px;
  margin-bottom: 30px;

  .mdc-data-table {
    width: 100%;
  }
}

input[type='file'] {
  opacity: 0;
}

.fileInput {
  position: relative;
  width: 100%;

  .fileName {
    position: absolute;
    top: 11px;
    left: 16px;
    font-size: 15px;
    color: rgba(0, 0, 0, .87);
  }

  + img {
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
    margin-bottom: 10px;
  }
}

.addTag {
  .rmSelect {
    height: max-content;
    padding: 15px 0;

    .rm__input input {
      position: unset;
    }
  }

}

.allTagsList {

  .tags {
    display: flex;

    .tag {
      border: 1px solid rgba(0, 0, 0, .12);
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
    }
  }

}

.addProduct, .addRobomartType, .addStorefront {
  .productImg {
    width: 100%;
    height: 200px;
    object-fit: contain;
    background-color: rgba(0, 0, 0, .12);
  }
}

.addProduct {
  .productImg {
    height: 140px;
  }
}

.inventory {
  .actions {
    margin-top: 30px;
    display: flex;

    .mdc-button {
      width: 100px;
      margin-right: 10px;
    }
  }

  a {
    color: unset;
    text-decoration: unset;
  }
}

.rights {
  &.invalid {
    .mdc-data-table {
      border-color: $red;
    }

    .title {
      color: $red;
    }
  }

  .title {
    font-size: 16px;
    margin: 0 0 10px 5px;
  }

  .mdc-data-table.blur {
    filter: blur(2px);

  }

  .mdc-data-table__table {
    width: 100%;

    .mdc-data-table__cell, .rmwc-data-table__cell {
      border-right: 1px solid rgba(0, 0, 0, .12);
    }
  }
}

.mdc-form-field.blur {
  filter: blur(2px);
}

.mdc-form-field > label {
  cursor: pointer;
}

.userForm {
  .groups {
    .title {
      font-size: 16px;
      margin: 5px 0 0 8px;


    }

    &.invalid {
      .title {
        color: $red;
      }

      ul {
        border: 1px solid $red;
      }
    }

    ul {
      li {
        border: 1px solid rgba(0, 0, 0, .12);
        border-bottom: unset;

        .mdc-form-field {
          cursor: pointer;

          .label {
            cursor: pointer;
          }
        }

        &:last-child {
          border: 1px solid rgba(0, 0, 0, .12);
        }
      }
    }
  }
}

.react-datepicker__tab-loop {
  position: absolute;
  top: 0;
  z-index: 24;
}

.settings {

  .row {
    border: 1px solid rgba(0, 0, 0, .12);
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 2px;

    .info {
      font-size: 14px;
    }

    .section {
      display: flex;
      justify-content: space-between;

      .leftCol, .rightCol {
        width: 49%;
      }

      .rightCol {

        .labels {
          @media (max-width: 1024px) {
            min-width: 70px;

          }
          min-width: 100px;
        }
      }
    }

    .title {
      font-size: 16px;
      display: flex;
      align-items: center;
    }

    .item {
      display: flex;
      align-items: center;
      font-size: 14px;
      @media (max-width: 1024px) {
        font-size: 12px;
      }

      .labels {
        min-width: 270px;
        @media (max-width: 1024px) {
          min-width: 70px;
        }

        span {
          display: flex;
          align-items: center;

          &.invalid {
            color: $red;
          }
        }
      }

      .inputs.full {
        width: 100%;
      }

      .inputs.textarea {
        width: 100%;

        .rmTextarea {
          .mdc-text-field {
            height: 150px;
            width: 100%;
          }
        }
      }

      > div {
        margin-right: 10px;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-start;

    .rmButton {
      width: 100px;
    }
  }
}

.userSettings {
  cursor: pointer;
  position: relative;

  .rmwc-avatar__icon, .rmwc-avatar__text {
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px;
  }

  .material-icons {
    font-size: 40px;
  }

  .dropdown {
    position: absolute;
    width: 250px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px;
    border: 1px solid rgba(0, 0, 0, .12);
    top: 63px;
    right: 0;
    border-radius: 5px;

    .profileInfo {
      padding: 10px;
      display: flex;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: -10px;
        right: 7px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #ccc transparent;
      }

      .titles {
        color: #333333;
        font-size: 14px;
        margin-left: 10px;

        .title {
          font-weight: 600;
        }
      }
    }

    ul {
      background-color: #fff;
      color: #333;

      li {
        border-top: 1px solid rgba(0, 0, 0, .12);
        font-size: 14px;
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;

        .material-icons {
          font-size: 16px;
          margin-right: 10px;
        }

        a {
          text-decoration: none;
          color: #333;
        }
      }
    }
  }
}

.profile {
  display: flex;
  height: 250px;
  justify-content: space-between;

  .profileImg {
    width: 29%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-color: rgba(0, 0, 0, .12);
    }

    .firstLetter {
      display: flex;
      border-radius: 50%;
      width: 150px;
      height: 150px;
      box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px;
      border: 1px solid rgba(0, 0, 0, .12);
      justify-content: center;
      align-items: center;
      font-size: 85px;
      color: rgba(0, 0, 0, .5);
    }
  }

  .inputs {
    width: 33%;
  }
}

.container.fleet {
  height: 100%;

  .list {
    height: 50%;
    border-top: 1px solid rgba(0, 0, 0, .12);
    padding: 10px;
    font-size: 14px;

    p {
      height: 30px;
    }

    .customers {
      height: calc(100% - 40px);
      overflow-y: auto;

    }

    .item {
      cursor: pointer;
      margin: 5px 0;
      color: #2f78bb;
    }
  }

  .leftBlock {
    width: 20%;
    background-color: #fff;

    .orders {
      height: 50%;

      .search {
        height: 60px;
        display: flex;
        justify-content: space-between;

        .rmInput {
          width: 62%;
        }

        .rmSelect {
          width: 36%;

          .rm__single-value {
            margin-top: 2px;
          }
        }
      }

      .customers {
        height: calc(100% - 60px);
        overflow-y: auto;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          font-weight: 600;
          position: relative;
          padding: 5px 10px;
          margin-bottom: 5px;
          cursor: pointer;

          div {
            flex: 1;
          }

          &.active {
            background-color: rgba(#ccc, 0.3);

          }

          .dot {
            font-size: 30px;
            position: absolute;
            left: 3px;
            top: -12px;

            &.flash {
              animation: fadeIn 1s infinite alternate;
            }
          }

          span {
            cursor: pointer;
          }

          .material-icons {
            cursor: pointer;
            font-size: 18px;
            color: #ccc;
          }

          .dropdown {
            position: absolute;
            box-shadow: inset 0 1px 3px 1px rgba(0, 0, 0, 0.1);
            background-color: #fff;
            right: 0;
            top: 20px;
            width: 100px;
            z-index: 5;
            border-radius: 5px;
            height: 30px;

            div {
              display: flex;
              align-items: center;
              width: 100%;
              height: 100%;
              padding: 5px;
              justify-content: center;

              .material-icons {
                margin-right: 5px;
                color: #333;
              }
            }
          }
        }
      }
    }

  }

  .centerBlock {
    width: 50%;

    .googleMaps {
      height: 100%;
    }
  }

  .rightBlock {
    width: 30%;
    background-color: #fff;

    .robomartsList {
      height: 50%;
      width: 100%;
      overflow-y: hidden;

      .vehicles {
        //display: flex;
        //justify-content: space-around;
        //align-items: center;
        border-radius: 0;
        overflow: visible;
        width: 100%;
        border: none;

        .mdc-data-table__table {
          border: 1px solid rgba(0, 0, 0, .12);
        }

        .rmwc-data-table__head {
          .rmwc-data-table__row {
            background-color: rgba(0, 0, 0, 0.12);

            .mdc-data-table__cell {
              height: 40px !important;

            }
          }

        }

        .vehicleName {
          text-decoration: underline;
          min-width: 87px;
          cursor: pointer;

        }

        .check {
          cursor: pointer;
          opacity: 0.5;

          &:hover {
            opacity: 1;
          }
        }

        .dots {
          cursor: pointer;
          position: relative;
          overflow: visible;

          .list {
            position: absolute;
          }
        }

        .videoCam {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0.4;

          img {
            width: 28px;
            height: 24px;
          }
        }
      }

      .selected {
        .mdc-data-table__cell {
          color: #1fb91c;

        }
      }

      .highlighted {
        background: #ffec96;
      }
    }

  }
}


.map {
  position: relative;
  width: 100%;
  height: 100%;

  .filters {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 220px;
    background: none padding-box rgb(255, 255, 255);
    text-transform: none;
    appearance: none;
    cursor: pointer;
    user-select: none;
    direction: ltr;
    text-align: center;
    height: 40px;
    vertical-align: middle;
    color: rgb(86, 86, 86);
    font-size: 18px;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
    min-width: 320px;
    display: flex;
    justify-content: space-around;
    overflow: visible;
  }
}

.filterSelect {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  padding: 0 5px;
  height: 100%;


  &.noBorder {
    border-right: unset;
  }

  &.country {
    min-width: 100px;
  }

  .label {
    display: flex;
    align-items: center;
  }


}

.filterSelectContainer {
  position: relative;

  &.disabled {
    opacity: 0.5;

    .filterSelect {
      cursor: default;

    }
  }
}

.dropdownFilter {
  position: absolute;
  top: 40px;
  left: -4px;
  background-color: #fff;

  input {
    width: 200px;
    height: 35px;
    border-radius: 2px;
    border: 1px solid #ccc;
    padding: 0 5px;
    font-size: 14px;
    margin: 10px;

    &:focus {
      outline: none;
    }
  }

  ul {
    width: 100%;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

@keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;

  }
  to {
    opacity: 1.0;

  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1.0;
  }
}


@keyframes fade1 {
  0% {
    background: rgba($red, 0.3);
  }
  50% {
    background: rgba($red, 0.6);

  }
  100% {
    background: rgba($red, 0);
  }
}

.blink {
  animation: fade 2000ms;
  animation-iteration-count: infinite;
}


.consumerData {
  display: flex;
  justify-content: space-between;

  .rmInput {
    width: 48%;
  }
}

.infoWindow {
}

.gm-style {
  //.gm-style-iw-t::after {
  //  display: none !important;
  //}

  .gm-ui-hover-effect {
    //display: none !important;
    outline: none !important;
  }
}

.googleMaps {
  iframe {
    + div {
      border: unset !important;
    }
  }

  opacity: 0;

  &.bounded {
    opacity: 1;
  }
}

.orderReceipt {
  margin-bottom: 20px;

  .robomartData {
    display: flex;

    .img {

      img {
        width: 100px;
        max-height: 100px;
        border-radius: 50%;
        object-fit: contain;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 20px;

      .vehicleName, .requestTime {
        font-size: 15px;
      }
    }
  }

  .mdc-data-table {
    width: 100%;

    .mdc-data-table__cell, .rmwc-data-table__cell {
      border-right: 1px solid rgba(0, 0, 0, .12);
      overflow: unset;
    }

    .item {
      div {
        display: flex;
        align-items: center;
        padding: 5px;

        .icon {
          cursor: pointer;
          font-size: 15px;
        }

        img {
          width: 70px;
          max-height: 70px;
          margin-right: 10px;
          object-fit: contain;
        }

        a {
          text-decoration: none;
          color: $primary;
        }
      }
    }

    .subtotal {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;

      &.total {
        font-weight: 600;
        border-top: 1px solid rgba(0, 0, 0, .12);
      }
    }
  }

  .tagGroup {
    padding: unset;

    .mdc-data-table {
      border: none;

      .rmwc-data-table__head {
        background: rgba(0, 0, 0, .04);

      }
    }
  }
}

.mdc-checkbox--disabled {
  opacity: 0.5;
  cursor: default;

  + label {
    cursor: default;
    opacity: 0.5;

  }
}

.blinkVehicle {
  .robomartListItem {
    animation: fade1 2200ms;
    -webkit-animation: fade1 2200ms;
    animation-iteration-count: infinite;
  }
}

.rmInput {
  .mdc-text-field__input:-webkit-autofill + .mdc-floating-label {
    transform: translateY(-83%) scale(0.75);
    cursor: auto;
  }
}

.checkboxes {
  display: flex;
  flex-direction: column;

  .mdc-form-field {
    margin-top: 17px;
  }
}


@keyframes pulse {
  0% {
    transform: scale(0.2);
  }
  10% {
    transform: scale(0.5);
  }
  20% {
    transform: scale(0.7);
  }
  30% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1.6);
  }
}

img[src$="?customerMarkerId=customerMarkerPulsing"] {
  border-radius: 100%;
  top: 38px !important;
  left: 20px !important;
  width: 40px !important;
  height: 40px !important;
  z-index: 10;
  animation: pulse 3000ms ease-out;
  animation-iteration-count: infinite;

}

#offline {
  position: fixed;
  width: 100%;
  background: #f83f37;
  color: #ffffff;
  top: 0;
  right: 0;
  left: auto;
  text-align: center;
  padding: 2px 10px;
  animation-name: offline;
  animation-duration: 500ms;
  z-index: 99999999;
  opacity: 0.5;
  font-size: 15px;
  @keyframes offline {
    from {
      top: -20px;
    }
    to {
      top: 0;
    }
  }
}

.xIcon {
  position: absolute;
  right: 0;
  width: 25px;
  cursor: pointer;
}

.ratingContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  border-top: 1px solid rgba(0, 0, 0, .12);
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  padding-top: 10px;
  padding-bottom: 10px;

  p {
    font-weight: 400;
  }

  p:first-child {
    margin-bottom: 20px;
  }

  .feedbackTxt {
    display: flex;
    width: 400px;
  }
}

.ordersList{
  .notificationContainer{
    display: flex;
    margin-bottom: 20px;
  }
  .notification{
    display: flex;
    margin-right: 40px;
    align-items: center;

  }
  .big{
    font-size: 25px;
  }
  .blank{
    color: green;
  }
}